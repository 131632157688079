
/* HomePage.css */
.home {
    .selected {
        border: 2px solid white;
    }
    /* Add your styles for HomePage here */
}
.confirmation-button {
    background-color: #4CAF50; /* Green background */
    border: none; /* Remove border */
    color: white; /* White text */
    padding: 15px 32px; /* Some padding */
    text-align: center; /* Centered text */
    text-decoration: none; /* Remove underline */
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer; /* Mouse pointer on hover */
    border-radius: 12px; /* Rounded corners */
    transition-duration: 0.4s; /* Transition effect */
}

.confirmation-button:hover {
    background-color: white; /* White background */
    color: black; /* Black text */
}